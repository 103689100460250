import React, { useState, useEffect } from 'react';
import DailyFeel from "./components/DailyFeel/DailyFeel";
import DailySessions from "./components/DailySessions/DailySessions";
import Quote from "./Quote/Quote";
import NewsList from "./News/NewsList";
import {handleResize} from "../../utils";

export default function Main() {
    const [isExpanded, setIsExpanded] = useState(false);


    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };



    return (
        <div  style={{ paddingBottom: handleResize() }}   className="w-full h-screen flex flex-col">
            <div  className="flex-grow">
                {!isExpanded && (
                    <>
                        <DailyFeel />
                        <DailySessions />
                        <Quote />
                    </>
                )}
                <NewsList isExpanded={isExpanded} toggleExpand={toggleExpand} />
            </div>
        </div>
    );
}
