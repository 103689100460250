import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListCard from './items/ListCard';
import { Steps, message, Modal, Button } from 'antd';
import { contentBalls, getMyResults, saveTestScore } from "../../../../api/Tets/Tests";
import { setUserScores } from "../../../../store/slices/userScoresSlice";

const { Step } = Steps;

const TestList = () => {
    const guide = useSelector(state => state.cardData.guide);
    const userScores = useSelector(state => state.user_scores.user_scores);
    const dispatch = useDispatch();
    const [completedTests, setCompletedTests] = useState({});
    const [totalScore, setTotalScore] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ballsList, setBallsList] = useState([]);
    const [resultDescription, setResultDescription] = useState(""); // Для хранения описания результата
    const [oldResult, setOldResult] = useState(null); // Для хранения старого результата

    const getUserScore = async () => {
        const data = await getMyResults();
        dispatch(setUserScores(data));
    };

    const handleComplete = (testId, selectedBall, previousBall) => {
        setCompletedTests(prevState => ({
            ...prevState,
            [testId]: selectedBall
        }));
        setTotalScore(totalScore - (previousBall || 0) + selectedBall);
    };

    // Проверка завершены ли все тесты
    const allTestsCompleted = guide?.tests?.every(test => completedTests[test.id] !== undefined);

    // Функция для поиска старого результата, если тест уже завершен
    const findOldResult = (guideId) => {
        const userScore = userScores.find(score => score.content_id === guideId);
        if (userScore) {
            // Сортируем ballsList по возрастанию, чтобы проверять по порядку
            const sortedBalls = ballsList.sort((a, b) => a.score - b.score);

            // Проходим по списку и ищем результат для userScore
            for (let i = 0; i < sortedBalls.length; i++) {
                const current = sortedBalls[i];

                // Ищем первый результат, который больше или равен результату пользователя
                if (userScore.score <= current.score) {
                    setOldResult(current);
                    return;
                }
            }

            // Если результат пользователя выше всех в списке, показываем последний элемент
            if (sortedBalls.length > 0) {
                setOldResult(sortedBalls[sortedBalls.length - 1]);
            }
        }
    };

    // Открыть модальное окно автоматически, когда все тесты завершены
    useEffect(() => {
        if (allTestsCompleted) {
            checkResult(); // Проверяем новый результат перед показом модального окна
            handleMyTestScore(); // Сохраняем новый результат автоматически
            setIsModalVisible(true); // Открываем модальное окно
        }
    }, [allTestsCompleted]);

    // Закрытие модального окна
    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    // Функция для отправки результатов теста
    const handleMyTestScore = async () => {
        try {
            const testId = guide.id; // Получаем ID теста
            await saveTestScore({ score: totalScore, content_id: testId }); // Передаем данные в API
            getUserScore();
            message.success('Результаты теста успешно сохранены!');
        } catch (error) {
            message.error('Ошибка при сохранении результатов.');
        }
    };

    // Получаем список возможных баллов и описаний
    useEffect(() => {
        fetchBallsList();
    }, []);

    useEffect(() => {
        if (ballsList.length > 0 && guide) {
            findOldResult(guide.id);
        }
    }, [ballsList, guide]);

    const fetchBallsList = async () => {
        const data = await contentBalls(guide.id);
        setBallsList(data);
    };

    // Функция для проверки нового результата на основе общего балла
    const checkResult = () => {
        const sortedBalls = ballsList.sort((a, b) => a.score - b.score);

        for (let i = 0; i < sortedBalls.length; i++) {
            const current = sortedBalls[i];

            if (totalScore <= current.score) {
                setResultDescription(current.description);
                return;
            }
        }

        if (sortedBalls.length > 0) {
            setResultDescription(sortedBalls[sortedBalls.length - 1].description);
        }
    };

    if (!guide || !guide.tests) {
        return null;
    }

    return (
        <div className="flex flex-col h-full">
            {/* Кнопка для показа старого результата */}
            <div className="p-4">
                <Button
                    style={{ width: '100%', height: '30px', backgroundColor: '#1890ff', color: 'white' }}
                    onClick={() => message.info(`Ваш старый результат: ${oldResult ? oldResult.score : 'Нет данных'}`)}
                >
                    {oldResult ? `Ваш старый результат: ${oldResult.description}` : 'Нет старых результатов'}
                </Button>
            </div>

            <div className="flex-grow overflow-auto p-4">
                <Steps direction="vertical" current={Object.keys(completedTests).length}>
                    {guide.tests.map((test, index) => (
                        <Step
                            key={index}
                            status={completedTests[test.id] !== undefined ? 'finish' : 'wait'}
                            title={`Test ${index + 1}`}
                            description={
                                <ListCard test={test} onComplete={handleComplete} previousBall={completedTests[test.id]} />
                            }
                        />
                    ))}
                </Steps>
            </div>

            {/* Модальное окно с результатом */}
            <Modal
                title="Результаты теста"
                visible={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={null} // Отключаем стандартные кнопки
            >
                <p>Ваш новый общий результат: {totalScore}</p>
                <p>Описание нового результата: {resultDescription}</p>
            </Modal>
        </div>
    );
};

export default TestList;
