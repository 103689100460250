import React, { useState } from 'react';
import { FaHome, FaVideo, FaComment, FaUsers, FaMicrophone } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveItem } from '../../store/slices/bottomBarSlice';
import VoiceAssistant from '../../utils/VoiceAssistant';
import './VoiceAssistant.css'; // Импортируйте CSS-файл

const BottomBar = () => {
    const activeItem = useSelector((state) => state.bottomBar.activeItem);
    const dispatch = useDispatch();
    const [listening, setListening] = useState(false);

    const icons = [
        { name: 'home', icon: <FaHome />, label: 'Home' },
        { name: 'sessions', icon: <FaVideo />, label: 'Sessions' },
        { name: 'tests', icon: <FaComment />, label: 'Tests' },
        { name: 'messages', icon: <FaUsers />, label: 'Messages' },
    ];

    const handleLongPress = () => {
        setListening(true);
    };

    const handleLongPressEnd = () => {
        setListening(false);
    };

    return (
        <div id={'bottom-bar'} className="fixed bottom-0 left-0 right-0 bg-white shadow-lg py-2 z-10 flex justify-around items-center border-t border-gray-200">
            {icons.slice(0, 2).map((item) => (
                <motion.div
                    key={item.name}
                    onClick={() => dispatch(setActiveItem(item.name))}
                    className={`relative flex flex-col items-center cursor-pointer ${activeItem === item.name ? 'border border-orange-500 rounded-md p-1' : ''}`}
                    whileTap={{ scale: 0.9 }}
                >
                    {activeItem === item.name && (
                        <motion.div
                            className="absolute -bottom-1 w-full h-[1px] bg-orange-500 rounded-full"
                            layoutId="highlight"
                        />
                    )}
                    <item.icon.type className={`text-2xl ${activeItem === item.name ? 'text-orange-500' : 'text-gray-400'}`} />
                    <span className={`text-xs ${activeItem === item.name ? 'text-orange-500' : 'text-gray-400'}`}>{item.label}</span>
                </motion.div>
            ))}
            <div
                className="relative flex flex-col items-center cursor-pointer"
                onMouseDown={handleLongPress}
                onMouseUp={handleLongPressEnd}
                onTouchStart={handleLongPress}
                onTouchEnd={handleLongPressEnd}
            >
                <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full border border-gray-400">
                    <FaMicrophone className={`text-2xl ${listening ? 'text-orange-500' : 'text-gray-400'}`} />
                </div>
                <span className={`text-xs ${listening ? 'text-orange-500' : 'text-gray-400'}`}>Voice</span>
            </div>
            {icons.slice(2).map((item) => (
                <motion.div
                    key={item.name}
                    onClick={() => dispatch(setActiveItem(item.name))}
                    className={`relative flex flex-col items-center cursor-pointer ${activeItem === item.name ? 'border border-orange-500 rounded-md p-1' : ''}`}
                    whileTap={{ scale: 0.9 }}
                >
                    {activeItem === item.name && (
                        <motion.div
                            className="absolute -bottom-1 w-full h-[1px] bg-orange-500 rounded-full"
                            layoutId="highlight"
                        />
                    )}
                    <item.icon.type className={`text-2xl ${activeItem === item.name ? 'text-orange-500' : 'text-gray-400'}`} />
                    <span className={`text-xs ${activeItem === item.name ? 'text-orange-500' : 'text-gray-400'}`}>{item.label}</span>
                </motion.div>
            ))}
            {listening && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <motion.div
                        className="bg-white p-4 rounded-lg shadow-lg"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VoiceAssistant listening={listening} onClose={() => setListening(false)} />
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default BottomBar;
