import React from 'react';
import { Avatar, Button, Typography } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const SessionCard = ({ session }) => {
    const sessionTime = moment(session.session_time);
    const isPast = sessionTime.isBefore(moment());
    const isToday = sessionTime.isSame(moment(), 'day');

    // Форматируем дату и время
    const formattedDate = sessionTime.format('YYYY-MM-DD');
    const formattedTime = sessionTime.format('HH:mm');

    // Извлекаем первые буквы имени и фамилии
    const getInitials = (firstName, lastName) => {
        return `${firstName[0]}${lastName[0]}`.toUpperCase();
    };

    return (
        <div className={`bg-${isPast ? 'orange-100' : 'gray-100'} p-4 rounded-xl shadow-md mb-4`}>
            <div className="flex items-center mb-4">
                <Avatar
                    className="bg-blue-400"
                    size={50}
                    src={session.psychologist.avatar || null} // Если аватар не задан, показываем инициал
                >
                    {!session.psychologist.avatar && getInitials(session.psychologist.first_name, session.psychologist.last_name)}
                </Avatar>
                <div className="ml-4">
                    <Typography className="text-brown-900 font-bold">
                        {session.psychologist.first_name} {session.psychologist.last_name}
                    </Typography>
                    <Typography className="text-brown-700">Психолог</Typography>
                </div>
            </div>
            <div className="flex items-center mb-4">
                <CalendarOutlined className="text-gray-500 mr-2" />
                <Typography className="text-gray-500">{formattedDate}</Typography>
                <ClockCircleOutlined className="text-gray-500 ml-4 mr-2" />
                <Typography className="text-gray-500">{formattedTime}</Typography>
            </div>
            <div className="flex justify-between items-center">
                {isPast ? (
                    <Typography className="text-orange-500 font-bold">Сессия завершена</Typography>
                ) : (
                    <Button type="primary" className="bg-orange-500 border-none">
                        {isToday ? 'Начать сессию' : 'Запланировано'}
                    </Button>
                )}
                <Typography className={`text-${isPast ? 'orange-500' : 'gray-500'} font-bold`}>
                    {isPast ? 'Завершено' : 'Активно'}
                </Typography>
            </div>
        </div>
    );
};

export default SessionCard;
