import axios from 'axios';
import CryptoJS from 'crypto-js';

const baseURL = process.env.REACT_APP_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// Регистрация
export const registerUser = async (first_name, last_name, age, phone_number, about, password, password_confirmation) => {
    try {
        const response = await axios.post(`${baseURL}api/app/register`, {
            first_name,
            last_name,
            age,
            phone_number,
            about,
            password,
            password_confirmation,
        });
        return response.data;
    } catch (error) {
        console.error('Error during registration:', error.response);
        throw error;
    }
};

// Логин
export const loginUser = async (phone_number, password) => {
    try {
        const response = await axios.post(`${baseURL}api/login`, {
            phone_number,
            password,
        });
        const { token } = response.data;
        const { user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        return response.data;
    } catch (error) {
        console.error('Error during login:', error.response);
        throw error;
    }
};

// Шифрование данных
export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

// Расшифровка данных
export const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
};
