import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const psychologistsAPI = axios.create({
    baseURL,
});

export const getPsychologists = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(`${baseURL}api/psycho`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};