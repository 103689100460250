import React from 'react';
import TestHeader from "./items/TestHeader";
import TestList from "./components/TestList/TestList";
import {handleResize} from "../../utils";


export default function Tests(props) {

    return(
        <div style={{ paddingBottom: handleResize() }}  className={'w-full h-screen'}>
            <TestHeader/>
            <TestList/>
        </div>
    )
}