import React from "react";
import {Typography} from "antd";
import guide_icon from '../../assets/icons/guide_icon.png'
import GuideCArd from "./components/GuideCard/GuideCArd";
import GuidesList from "./components/GuidesList/GuidesList";

export default function Guides() {

    return (
        <div className="w-full overflow-hidden p-4">
            <div className="flex flex-row items-center w-full">
                <img src={guide_icon} alt="Guide Logo" width={'32px'} height={'32px'}/>
                <Typography className={'font-bold text-[24px] text-[#573926] ml-4'}>Metodika va
                    qo`llanmalar</Typography>
            </div>
            <Typography className={'font-bold text-[16px] mt-4'}>Siz uchun eng yaxshi metodika</Typography>
            <GuideCArd/>
            <GuidesList/>
        </div>
    )
}