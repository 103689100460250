import React, { useState, useEffect } from 'react';
import { Avatar, Badge, Typography, Button, Tooltip, Modal } from 'antd';
import { BellOutlined, InfoCircleOutlined, PhoneOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import avatar_1 from '../../assets/images/avatars/avatar-1.png';
import avatar_2 from '../../assets/images/avatars/avatar-2.png';
import avatar_3 from '../../assets/images/avatars/avatar-3.png';
import avatar_4 from '../../assets/images/avatars/avatar-4.png';
import avatar_5 from '../../assets/images/avatars/avatar-5.png';
import avatar_6 from '../../assets/images/avatars/avatar-6.png';

const avatars = {
    'avatar-1': avatar_1,
    'avatar-2': avatar_2,
    'avatar-3': avatar_3,
    'avatar-4': avatar_4,
    'avatar-5': avatar_5,
    'avatar-6': avatar_6
};

const Header = () => {
    const activeItem = useSelector((state) => state.bottomBar.activeItem);
    const user = JSON.parse(localStorage.getItem('user')) || 'Гость';
    const userAvatar = localStorage.getItem('avatar') || 'avatar-1';

    const [time, setTime] = useState(new Date());
    const [batteryLevel, setBatteryLevel] = useState(null);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [isFaqModalVisible, setIsFaqModalVisible] = useState(false);
    const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000);

        if ('getBattery' in navigator) {
            navigator.getBattery().then(battery => {
                setBatteryLevel(Math.floor(battery.level * 100));
                battery.addEventListener('levelchange', () => {
                    setBatteryLevel(Math.floor(battery.level * 100));
                });
            });
        }

        return () => clearInterval(timer);
    }, []);

    const formattedTime = `${time.getHours()}:${time.getMinutes() < 10 ? '0' : ''}${time.getMinutes()}`;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const showInfoModal = () => {
        setIsInfoModalVisible(true);
    };

    const handleInfoOk = () => {
        setIsInfoModalVisible(false);
    };

    const handleInfoCancel = () => {
        setIsInfoModalVisible(false);
    };

    const showFaqModal = () => {
        setIsFaqModalVisible(true);
    };

    const handleFaqOk = () => {
        setIsFaqModalVisible(false);
    };

    const handleFaqCancel = () => {
        setIsFaqModalVisible(false);
    };

    const showAvatarModal = () => {
        setIsAvatarModalVisible(true);
    };

    const handleAvatarOk = () => {
        setIsAvatarModalVisible(false);
    };

    const handleAvatarCancel = () => {
        setIsAvatarModalVisible(false);
    };

    const selectAvatar = (avatar) => {
        localStorage.setItem('avatar', avatar);
        setIsAvatarModalVisible(false);
        window.location.reload(); // Reload to update the avatar
    };

    return (
        <motion.div className="flex flex-col h-full w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <motion.div className="flex items-center w-full justify-between bg-white p-4 rounded-b-xl" initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }}>
                <div className="flex items-center space-x-4">
                    <Avatar
                        className="bg-gray-400 cursor-pointer header-avatar"
                        size={50}
                        src={avatars[userAvatar]}
                        onClick={showAvatarModal}
                    />
                    <div className="flex flex-col">
                        <Typography className="text-lg header-time">{formattedTime}</Typography>
                        <Typography className="text-xs text-gray-500">{timeZone}</Typography>
                        {batteryLevel !== null && (
                            <div className="flex items-center space-x-1 header-battery">
                                <div className="w-4 h-2 bg-gray-300 rounded-sm relative overflow-hidden">
                                    <div
                                        className="absolute top-0 left-0 h-full bg-green-500"
                                        style={{ width: `${batteryLevel}%` }}
                                    />
                                </div>
                                <Typography className="text-sm">{batteryLevel}%</Typography>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <Tooltip title="Позвонить в службу поддержки">
                        <Button shape="circle" icon={<PhoneOutlined />} onClick={() => window.location.href = 'tel:+998913310013'} className="header-support" />
                    </Tooltip>
                    <Tooltip title="Информация">
                        <Button shape="circle" icon={<InfoCircleOutlined />} onClick={showInfoModal} />
                    </Tooltip>
                    <Tooltip title="FAQ">
                        <Button shape="circle" icon={<QuestionCircleOutlined />} onClick={showFaqModal} />
                    </Tooltip>
                    <Badge count={3} size="small" offset={[0, 0]}>
                        <BellOutlined className="text-[28px] text-gray-700" />
                    </Badge>
                </div>
            </motion.div>
            {activeItem === 'home' && (
                <motion.div className="w-full flex flex-col space-y-1 ml-4 mt-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
                    <Typography className="text-[28px] text-[#371B34]">Xayrli kun,</Typography>
                    <Typography className="text-[28px] font-bold text-[#371B34]">{user?.first_name || 'гость'}!</Typography>
                </motion.div>
            )}

            {/* Информация Модальное окно */}
            <Modal
                title="Информация"
                centered
                visible={isInfoModalVisible}
                onOk={handleInfoOk}
                onCancel={handleInfoCancel}
                footer={[
                    <Button key="back" onClick={handleInfoCancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleInfoOk}>
                        Ок
                    </Button>,
                ]}
            >
                <p>Здесь будет содержаться информация о вашем приложении.</p>
            </Modal>

            {/* FAQ Модальное окно */}
            <Modal
                title="FAQ"
                centered
                visible={isFaqModalVisible}
                onOk={handleFaqOk}
                onCancel={handleFaqCancel}
                footer={[
                    <Button key="back" onClick={handleFaqCancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleFaqOk}>
                        Ок
                    </Button>,
                ]}
            >
                <p>Здесь будут ответы на часто задаваемые вопросы.</p>
            </Modal>

            {/* Выбор аватара Модальное окно */}
            <Modal
                title="Выбор аватара"
                centered
                visible={isAvatarModalVisible}
                onOk={handleAvatarOk}
                onCancel={handleAvatarCancel}
                footer={[
                    <Button key="back" onClick={handleAvatarCancel}>
                        Отмена
                    </Button>,
                ]}
            >
                <motion.div className="grid grid-cols-3 gap-4" initial="hidden" animate="visible" variants={{
                    hidden: { opacity: 0, scale: 0.8 },
                    visible: {
                        opacity: 1,
                        scale: 1,
                        transition: {
                            delay: 0.3,
                            staggerChildren: 0.2,
                        },
                    },
                }}>
                    {Object.keys(avatars).map((key) => (
                        <motion.div key={key} variants={{
                            hidden: { opacity: 0, y: 20 },
                            visible: { opacity: 1, y: 0 },
                        }}>
                            <Avatar
                                src={avatars[key]}
                                size={50}
                                className={`cursor-pointer ${userAvatar === key ? 'border-2 border-blue-500' : ''}`}
                                onClick={() => selectAvatar(key)} 
                            />   
                        </motion.div>
                    ))}
                </motion.div>
            </Modal>
        </motion.div>
    );
};

export default Header;
