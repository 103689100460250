import axios from 'axios';

const API_URL = 'https://service.lideryam.com/api';

export const register = (username, password) => {
    return axios.post(`${API_URL}/auth/register`, { username, password });
};

export const login = (username, password) => {
    return axios.post(`${API_URL}/auth/login`, { username, password });
};

export const sendMessage = async (recipientId, content) => {
    const token = localStorage.getItem('token');
    console.log('JWT Token:', token); // Отладка
    try {
        const response = await axios.post('http://localhost:3033/api/messages/send', {

            recipient_id: recipientId, // Убедитесь, что это корректный идентификатор пользователя
            content: content
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        console.log('Message sent:', response.data);
    } catch (error) {
        console.error('Error sending message:', error.response.data);
    }
};



export const getMessages = (token) => {
    return axios.get(`${API_URL}/messages/inbox`, {
        headers: { Authorization: `Bearer ${token}` }
    });
};

export const markAsRead = (token, id) => {
    return axios.put(`${API_URL}/messages/read/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
    });
};
