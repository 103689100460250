import React, {useEffect} from 'react';
import {PlayCircleOutlined, AudioOutlined, ReadOutlined, CheckOutlined, CheckSquareFilled} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setGuide } from "../../../../../../store/slices/cardDataSlice";
import placeholder from '../../../../../../assets/images/placeholder.jpg';
import {setActiveItem} from "../../../../../../store/slices/bottomBarSlice";
import {ImCheckboxUnchecked} from "react-icons/im";

const GuideCard = ({isCompleted, guide }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setGuide(guide));
        dispatch(setActiveItem('guide_page'))
    };

    useEffect(()=>{

    },[])
    return (
        <div onClick={handleClick} className="relative p-4 rounded-xl shadow-md flex flex-col justify-between bg-cover bg-center text-white cursor-pointer" style={{ backgroundImage: `url(${placeholder})`, backgroundSize: 'cover' }}>
            <div className="absolute inset-0  opacity-80 rounded-xl"></div>
            <div className="relative flex justify-between items-start">
                <div>
                    <h2 className="text-xl font-bold">{guide.title}</h2>
                    <p>{guide.subtitle}</p>
                </div>

            </div>
            <div className="relative flex items-center mt-4">
                <PlayCircleOutlined className="text-2xl mr-2" />
                <span className="font-bold">Play</span>
                <span className="ml-2">{guide.duration}</span>
            </div>
            <div className="relative flex justify-between items-center mt-4">
                <div className="flex items-center">
                    <AudioOutlined className="text-2xl mr-2" />
                    <span>{isCompleted}ta audio</span>
                </div>
                <div className="flex items-center">
                    {isCompleted ?      <CheckSquareFilled className="text-xl mr-2 text-green-400" /> :      <ImCheckboxUnchecked className="text-2xl mr-2" />}
                    <span>{guide.readingTime}</span>
                </div>
            </div>

        </div>
    );
};

export default GuideCard;
