import { configureStore } from '@reduxjs/toolkit';
import bottomBarReducer from './slices/bottomBarSlice';
import cardDataReducer  from './slices/cardDataSlice';
import categoriesReducer from './slices/categoriesSlice';
import userScoresReducer   from './slices/userScoresSlice';

export const store = configureStore({
    reducer: {
        bottomBar: bottomBarReducer,
        cardData: cardDataReducer,
        categories: categoriesReducer,
        user_scores: userScoresReducer,
    },
});

export default store;
