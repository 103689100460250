import React from 'react';
import { PlayCircleOutlined, AudioOutlined, ReadOutlined } from '@ant-design/icons';
import placeholder from '../../../../assets/images/placeholder.jpg';

const GuideCard = () => {
    return (
        <div className="relative p-4 rounded-xl shadow-md flex flex-col justify-between text-white" style={{ backgroundImage: `url(${placeholder})`, backgroundSize: 'ф', backgroundPosition: 'center' }}>
            <div className="absolute inset-0  opacity-80 rounded-xl"></div>
            <div className="relative flex justify-between items-start">
                <div>
                    <h2 className="text-xl font-bold">Buni bajaring</h2>
                    <p>foydali mashqlar</p>
                </div>
                <div className="w-24 h-24" />
            </div>
            <div className="relative flex items-center mt-4">
                <PlayCircleOutlined className="text-2xl mr-2" />
                <span className="font-bold">Play</span>
                <span className="ml-2">32 min</span>
            </div>
            <div className="relative flex justify-between items-center mt-4">
                <div className="flex items-center">
                    <AudioOutlined className="text-2xl mr-2" />
                    <span>8 ta audio</span>
                </div>
                <div className="flex items-center">
                    <ReadOutlined className="text-2xl mr-2" />
                    <span>2 min o'qish</span>
                </div>
            </div>
        </div>
    );
};

export default GuideCard;
