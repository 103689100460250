import React from 'react';
import { motion } from 'framer-motion';
import happy_icon from '../../../../assets/icons/feelICons/happy_feeel_icon.png';
import balance_icon from '../../../../assets/icons/feelICons/balance_feel_icon.png';
import relax_icon from '../../../../assets/icons/feelICons/relax_feel_icon.png';
import angry_icon from '../../../../assets/icons/feelICons/angry_feel_icon.png';

const feelings = [
    { color: '#EF5DA8', icon: happy_icon, label: 'Baxtiyor' },
    { color: '#AEAFF7', icon: balance_icon, label: 'balansda' },
    { color: '#A0E3E2', icon: relax_icon, label: 'xotirjam' },
    { color: '#F09E54', icon: angry_icon, label: 'Asabiy' },
];

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
};

const DailyFeel = () => {
    return (
        <div className="p-4">
            <h2 className="text-lg font-bold text-gray-900 mb-4">Bugun o`zingizni qanday his qilayapsiz ?</h2>
            <motion.div
                className="flex flex-row items-center space-x-4 w-full justify-between"
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: {},
                    visible: {
                        transition: {
                            staggerChildren: 0.2,
                        },
                    },
                }}
            >
                {feelings.map((feel, index) => (
                    <motion.div
                        key={index}
                        className="flex flex-col items-center"
                        variants={itemVariants}
                        whileTap={{ scale: 0.9 }}
                        whileHover={{ scale: 1.1 }}
                    >
                        <div
                            className="w-16 h-16 flex items-center justify-center rounded-lg"
                            style={{ backgroundColor: feel.color }}
                        >
                            <img src={feel.icon} alt={feel.label} className="w-8 h-8" />
                        </div>
                        <span className="text-gray-500 text-[12px] mt-2">{feel.label}</span>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default DailyFeel;
