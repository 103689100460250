import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user_scores: []  // Массив для хранения данных
};

const userScoresSlice = createSlice({
    name: 'user_scores',
    initialState,
    reducers: {
        // Метод для замены всего массива без дублирования
        setUserScores: (state, action) => {
            const newScores = action.payload;

            // Заменяем старые элементы новыми, если совпадает id
            state.user_scores = newScores.map(newScore => {
                // Найти существующий элемент с таким же id
                const existingScore = state.user_scores.find(score => score.id === newScore.id);

                // Если элемент существует, заменяем его новым, иначе добавляем новый
                return existingScore ? newScore : newScore;
            });
        },
    }
});

export const { setUserScores } = userScoresSlice.actions;

export default userScoresSlice.reducer;
