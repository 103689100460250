import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DialogGetCategories } from "../../api/Categories/categories_api";

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (lang) => {
    const response = await DialogGetCategories(lang);
    const testCategories = response.filter(category => category.contents.some(content => content.type === 'test'));
    const newsCategories = response.filter(category => category.contents.some(content => content.type === 'news'));
    return { testCategories, newsCategories };
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        testCategories: [],
        newsCategories: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.testCategories = action.payload.testCategories;
                state.newsCategories = action.payload.newsCategories;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default categoriesSlice.reducer;
