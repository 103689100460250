export const steps = [
    {
        target: '.header-avatar',
        content: 'Это ваш аватар. Нажмите на него, чтобы изменить его.',
    },
    {
        target: '.header-time',
        content: 'Здесь отображается текущее время.',
    },
    {
        target: '.header-battery',
        content: 'Здесь вы можете увидеть уровень заряда батареи.',
    },
    {
        target: '.header-support',
        content: 'Нажмите здесь, чтобы позвонить в службу поддержки.',
    },
    {
        target: '.sessions-main-tour',
        content: 'Buyerda sessiyalarga o`tsayiz bo`ladi',
    },
    // Добавьте больше шагов по мере необходимости
];