import React, { useEffect, useState } from 'react';
import { Typography } from "antd";
import CurrentSessionCard from "./components/CurrentSessionCard";
import SessionsList from "./components/SessionsList/SessionsList";
import { handleResize } from "../../utils";
import { getMySessions } from "../../api/sessions/sessions";
import moment from 'moment';

export default function Sessions() {
    const [sessions, setSessions] = useState([]);
    const [currentSession, setCurrentSession] = useState(null);

    const fetchSessions = async () => {
        const sessions = await getMySessions();
        setSessions(sessions);

        // Проверяем, есть ли сессия на сегодня
        const todaySession = sessions.find(session =>
            moment(session.session_time).isSame(moment(), 'day')
        );

        // Если сессия на сегодня найдена, сохраняем её в state
        if (todaySession) {
            setCurrentSession(todaySession);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    return (
        <div style={{ paddingBottom: handleResize() }} className={'flex flex-col overflow-y-scroll overflow-x-hidden p-4'}>
            {/* page title */}
            <Typography className={'text-[30px] font-bold uppercase text-[#573926] m-4'}>Sessions</Typography>

            {/* Показываем карточку текущей сессии, если она есть */}
            {currentSession && <CurrentSessionCard session={currentSession} />}

            {/* Список всех сессий */}
            <SessionsList sessions={sessions} />
        </div>
    );
}
