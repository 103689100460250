import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const categoryAPI = axios.create({
    baseURL,
});

// Получить все категории
export const DialogGetCategories = async (lang) => {
    const token = localStorage.getItem('token');
    console.log(baseURL);
    if (!token) {
        throw new Error('Token not found');
    }

    try {
        console.log(`Base URL: ${baseURL}`);
        console.log(`Request URL: ${baseURL}api/app/category?lang=${lang}`);

        const response = await categoryAPI.get(`/api/app/category?lang=${lang}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error response:', error.response);
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
