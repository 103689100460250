import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

const colors = ['#6DD3CE', '#8367C7', '#85CB33', '#5DA9E9', '#D36D85', '#E7C200'];

const ListCard = ({ test, onComplete, previousBall }) => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [showAnswers, setShowAnswers] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

    useEffect(() => {
        setBackgroundColor(colors[Math.floor(Math.random() * colors.length)]);
    }, []);

    const handleAnswerChange = (e) => {
        setSelectedAnswer(e.target.value);
    };

    const handleSubmit = () => {
        if (selectedAnswer !== null) {
            const selectedBall = test.answers[selectedAnswer].points;
            onComplete(test.id, selectedBall, previousBall);
            setShowAnswers(false); // Закрыть карточку после ответа
        }
    };

    const toggleShowAnswers = () => {
        setShowAnswers(!showAnswers);
    };

    // Проверка наличия данных
    if (!test || !test.question) {
        return <div>Loading...</div>;
    }
    const api_url = process.env.REACT_APP_URL;
    console.log()
    return (
        <div
            className="p-4 rounded-xl shadow-md flex flex-col md:flex-row items-center cursor-pointer"
            onClick={toggleShowAnswers}
            style={{ backgroundColor: backgroundColor }}
        >
            <div className={'flex flex-row'}>
            <div className="flex-grow text-white">
                <h2 className="text-xl font-semibold mb-2">{test.question}</h2>
                <p>birinchi qadam</p>
                <div className="flex items-center mt-2">
                    <PlayCircleOutlined className="text-xl mr-2" />
                    <span className="mr-4">Play</span>
                    <span>{test.duration}</span>
                </div>
            </div>
            <img src={`${api_url}storage/${JSON.parse(test.images)[0]}`} alt="Guide" className="w-24 h-24 rounded-xl ml-4" />
            </div>
            {showAnswers && (
                <div className="mt-4 w-full md:w-auto md:ml-4" onClick={e => e.stopPropagation()}>
                    <Radio.Group onChange={handleAnswerChange} value={selectedAnswer}>
                        {test.answers.map((answer, index) => (
                            <Radio key={index} value={index} className="block mb-2 text-white">
                                {answer.answer_text}
                            </Radio>
                        ))}
                    </Radio.Group>
                    <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded" onClick={handleSubmit} disabled={selectedAnswer === null}>
                        OK
                    </button>
                </div>
            )}
        </div>
    );
};

export default ListCard;
