import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const psychologistsAPI = axios.create({
    baseURL,
});

export const getMySessions = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(
            `${baseURL}api/app/sessions`,
             // Передаем данные (score и content_id) в тело запроса
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        console.log(error);
        throw error;
    }
};