import React, { useState, useEffect } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import { Modal, Button, Input } from 'antd';
import sessionIcon from '../../../assets/icons/sessions_icon.png';
import guideIcon from '../../../assets/icons/managemnt_icon.png';
import { useDispatch } from 'react-redux';
import { setActiveItem } from '../../../store/slices/bottomBarSlice';
import { motion } from 'framer-motion';

const Quote = () => {
    const dispatch = useDispatch();
    const [quote, setQuote] = useState(localStorage.getItem('quote') || '“Harakat qil o’xshasa omad, o’xshamasa tajriba”');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newQuote, setNewQuote] = useState('');
    const [touchStart, setTouchStart] = useState(0);

    const handleLongPress = () => {
        setIsModalVisible(true);
    };

    const handleTouchStart = (e) => {
        setTouchStart(Date.now());
    };

    const handleTouchEnd = (e) => {
        const touchDuration = Date.now() - touchStart;
        if (touchDuration > 500) {
            handleLongPress();
        }
    };

    const handleOk = () => {
        setQuote(newQuote);
        localStorage.setItem('quote', newQuote);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const resetQuote = () => {
        setQuote('“Harakat qil o’xshasa omad, o’xshamasa tajriba”');
        localStorage.removeItem('quote');
    };

    useEffect(() => {
        setQuote(localStorage.getItem('quote') || '“Harakat qil o’xshasa omad, o’xshamasa tajriba”');
    }, []);

    return (
        <div className="p-2 space-y-4">
            <motion.div
                className="flex flex-row items-center justify-center space-x-4"
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <button onClick={() => { dispatch(setActiveItem('sessions')) }} className="bg-[#F4F3F1] p-4 rounded-xl flex items-center space-x-2">
                    <img src={sessionIcon} alt="Sessiyalar" className="w-6 h-6" />
                    <span className="text-[#573926] font-bold">Sessiyalar</span>
                </button>
                <button onClick={() => { dispatch(setActiveItem('tests')) }} className="bg-[#F4F3F1] p-4 rounded-xl flex items-center space-x-2">
                    <img src={guideIcon} alt="Qo’llanmalar" className="w-6 h-6" />
                    <span className="text-[#573926] font-bold">Qo’llanmalar</span>
                </button>
            </motion.div>
            <motion.div
                className="bg-[#F4F4F4] p-6 rounded-xl flex items-center justify-between"
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <p className="text-gray-700 text-lg font-medium">
                    {quote}
                </p>
                <FaQuoteLeft className="text-[#D9D8D8] text-4xl" />
            </motion.div>
            <Modal
                title="Добавить свою цитату"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Ок
                    </Button>,
                    <Button key="reset" type="default" onClick={resetQuote}>
                        Вернуть на исходную
                    </Button>,
                ]}
            >
                <Input.TextArea
                    rows={4}
                    value={newQuote}
                    onChange={(e) => setNewQuote(e.target.value)}
                    placeholder="Введите свою цитату"
                />
            </Modal>
        </div>
    );
};

export default Quote;
