import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const psychologistsAPI = axios.create({
    baseURL,
});

export const saveTestScore = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.post(
            `${baseURL}api/app/score`,
            data, // Передаем данные (score и content_id) в тело запроса
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        console.log(error);
        throw error;
    }
};

export const getMyResults = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(
            `${baseURL}api/app/my-scores`,
           // Передаем данные (score и content_id) в тело запроса
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        console.log(error);
        throw error;
    }
};

export const contentBalls = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await psychologistsAPI.get(
            `${baseURL}api/app/content/${id}/balls`,
            // Передаем данные (score и content_id) в тело запроса
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        console.log(error);
        throw error;
    }
};
