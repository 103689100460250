import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    guide: null, // Изначально нет выбранной карточки
};

const cardDataSlice = createSlice({
    name: 'cardData',
    initialState,
    reducers: {
        setGuide: (state, action) => {
            state.guide = action.payload;
        },
        clearGuide: (state) => {
            state.guide = null;
        },
    },
});

export const { setGuide, clearGuide } = cardDataSlice.actions;
export default cardDataSlice.reducer;
