import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeItem: 'home',
};

const bottomBarSlice = createSlice({
    name: 'bottomBar',
    initialState,
    reducers: {
        setActiveItem: (state, action) => {
            state.activeItem = action.payload;
        },
    },
});

export const { setActiveItem } = bottomBarSlice.actions;
export default bottomBarSlice.reducer;
