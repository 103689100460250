import React, { useState } from 'react';
import { loginUser, registerUser, encryptData } from './AuthService';
import { Input, Button, Checkbox, Form, Typography, Divider } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [form] = Form.useForm();

    const handlePhoneNumber = (phone) => {
        // Удаляем все символы, кроме цифр, и обрезаем до 12 символов
        return phone.replace(/\D/g, '').slice(-12);
    };

    const handleSubmit = async (values) => {
        values.phone_number = handlePhoneNumber(values.phone_number);

        if (values.phone_number.length !== 12) {
            console.error('Phone number must be 12 digits long.');
            return;
        }

        if (isLogin) {
            try {
                const data = await loginUser(values.phone_number, values.password);
                localStorage.setItem('token', data.token);
                localStorage.setItem('login', encryptData(values.phone_number));
                localStorage.setItem('password', encryptData(values.password));
                localStorage.setItem('remember_me', values.remember_me ? 'true' : 'false');
                window.location.reload(); // Перезагрузка страницы для обновления состояния
            } catch (error) {
                console.error('Error during login:', error);
            }
        } else {
            try {
                await registerUser(
                    values.first_name,
                    values.last_name,
                    values.age,
                    values.phone_number,
                    values.about,
                    values.password,
                    values.password_confirmation
                );
                setIsLogin(true); // Переход на страницу логина после успешной регистрации
            } catch (error) {
                console.error('Error during registration:', error);
            }
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full mx-auto mt-10 p-6 border border-gray-200 rounded-lg bg-white shadow-lg">
                <div className="text-center mb-6">
                    <img src="/path/to/logo.png" alt="Logo" className="mx-auto mb-4" /> {/* Укажите путь к вашему логотипу */}
                    <Title level={2}>{isLogin ? 'Login' : 'Register'}</Title>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{ remember_me: false }}
                >
                    {!isLogin && (
                        <>
                            <Form.Item
                                name="first_name"
                                label="First Name"
                                rules={[{ required: true, message: 'Please enter your first name' }]}
                            >
                                <Input prefix={<UserOutlined />} placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name="last_name"
                                label="Last Name"
                                rules={[{ required: true, message: 'Please enter your last name' }]}
                            >
                                <Input prefix={<UserOutlined />} placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                                name="age"
                                label="Age"
                                rules={[{ required: true, message: 'Please enter your age' }]}
                            >
                                <Input type="number" placeholder="Age" />
                            </Form.Item>
                            <Form.Item name="about" label="About">
                                <Input.TextArea prefix={<InfoCircleOutlined />} placeholder="About" />
                            </Form.Item>
                            <Divider />
                        </>
                    )}
                    <Form.Item
                        name="phone_number"
                        label="Phone Number"
                        rules={[{ required: true, message: 'Please enter your phone number' }]}
                    >
                        <Input prefix={<PhoneOutlined />} placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please enter your password' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>
                    {!isLogin && (
                        <Form.Item
                            name="password_confirmation"
                            label="Confirm Password"
                            rules={[{ required: true, message: 'Please confirm your password' }]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
                        </Form.Item>
                    )}
                    {isLogin && (
                        <Form.Item name="remember_me" valuePropName="checked">
                            <Checkbox>Remember Me</Checkbox>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="w-full">
                            {isLogin ? 'Login' : 'Register'}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="link" onClick={() => setIsLogin(!isLogin)} className="w-full text-center">
                            {isLogin ? 'Switch to Register' : 'Switch to Login'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Auth;
